@import '~bootstrap/dist/css/bootstrap';
@import '~startbootstrap-stylish-portfolio/css/stylish-portfolio';
@import '~simple-line-icons/css/simple-line-icons.css';

/* Extended class not available in per-component SCSS. */
section.skills {
  &#devskills { P.tag { @extend .text-faded; } }
  &#opskills { P.tag { @extend .text-muted; } }
}

