header.masthead {
  background-image: url('./assets/background.jpg');
  background-attachment: fixed;
  background-size: cover;

  h1, h3 {
    text-shadow:2px 2px 10px #000, 2px 2px 10px #ccc;
    color: white;
  }
}

